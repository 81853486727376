import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AppConstants } from '../constants/app-constants.constants';
import { ApplicationInsightsService } from '../monitoring/application-insights/application-insights.service';
import { AppService } from '../app.service';
import { AUTH_CONSTANTS } from './auth.constants';
import { AuthErrors } from '../enums/auth_errors';
import { AuthResponse, LoginBody, SessionBody } from '../interfaces/auth';
import { environment } from '../../environments/environment';
import { ILoginLabels } from '../interfaces/labels/login-labels.interface';
import { IMenuInterface, IMenuLabelsInterface } from '../interfaces/menu-interface';
import { IMenuLabels } from '../interfaces/labels/menu-labels.interface';
import { LanguageConstants } from '../constants/language.constants';
import { LanguageTranslateService } from '../services/translate/language-translate.service';
import { LocalStorageService } from '../services/utils/local-storage.service';
import { MENU_CONSTANTS } from '../constants/menu.constants';
import { MENU_ITEMS_MOCK } from '../mocks/menu-items.mock';
import { ModuleSequence } from '../enums/modules';
import { NotificationsService } from '../providers/notifications/notifications.service';
import { RoadnetService } from '../services/roadnet/roadnet-service';
import { RoleProvider } from '../providers/role/role-provider.service';
import { ToastrAlertsService } from '../services/utils/toastr-alerts.service';
import { User, ViewData } from '../interfaces';
import { ViewProvider } from '../providers/view/view-provider.service';

import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';

const ALLOWPROFILES = ['Embarcador Biis', 'Administrador', 'Sysadmin', 'Embarcador Planeador', 'Transportista Biis'];
const apiUrl = environment.apiUrl;
const AUTHCOOKIE = 'auth';
const COOKIENAME_AUTH = 'auth';
const COOKIENAME_SESSION = 'session';
const EXTERNALLOGOUT = '/biis/logout';
const KEY_ALLOWEDROUTES = 'allowed_routes';
const KEY_ITEMS = 'items';
const KEY_MODULENAME = 'module.name';
const KEY_ROUTERLINK = 'routerLink';
const KEY_SHIPMENT_ICON = 'shipments';
const KEY_VIEW = 'view';
const PROGRESS_ANIMATION = 'increasing';
const ROUTE_HOME = '/home';
const ROUTE_NOPERMISSION = '/no-permission';
const ROUTE_ROADNET = 'roadnet/';
const USER_INFO_LOCAL_ID = 'userInfo';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public currentUser: string;
  public isRedirecting: boolean;
  public loginLabels: ILoginLabels;
  public menuLabels: IMenuLabels;
  public menuElements: Array <IMenuLabelsInterface>;

  constructor(
    private _appService: AppService,
    private _languageTranslateService: LanguageTranslateService,
    private applicationInsightsService: ApplicationInsightsService,
    private cookieService: CookieService,
    private http: HttpClient,
    private local: LocalStorageService,
    private notificationsService: NotificationsService,
    private roadnetService: RoadnetService,
    private roleProvider: RoleProvider,
    private router: Router,
    private toast: ToastrAlertsService,
    private toastr: ToastrService,
    private viewProvider: ViewProvider,
  ) {
    this.menuElements = MENU_CONSTANTS;
    this.isRedirecting = false;
  }

  /**
   * @description Gets Menu Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getMenuLabels(): Promise<void> {
    this.menuLabels = await this._languageTranslateService
    .getLanguageLabels(LanguageConstants.MENU_LABELS)
    .catch(() => {});
  }

  /**
   * @description Gets Login Labels from translate JSON files.
   * @return {Promise<void>}
   */
   public async getLoginLabels(): Promise<void> {
    this.loginLabels = await this._languageTranslateService
    .getLanguageLabels(LanguageConstants.LOGIN_LABELS)
    .catch(() => {});
  }

  /**
   * @description Get translate value for an menu element.
   *
   * @param {string} value Menu element to be translated.
   * @return {Promise<string>} Menu element translated.
   */
  public async getTranslatedMenuLabel(value: string): Promise<string> {
    let labelTranslated: string;
    if (value && this.menuLabels) {
      this.menuElements.forEach(element => {
        if (element.value === value) {
          if (this.menuLabels[element.label]) {
            labelTranslated = this.menuLabels[element.label];
          }
        }
      });
    }

    return labelTranslated ?? value;
  }

  /**
   * @description Translate menu Labels.
   * @return {Promise<IMenuInterface[]>}
   */
  public async translateMenu(menu: IMenuInterface[]): Promise<IMenuInterface[]> {
    await this.getMenuLabels();

    menu.forEach(async (module) => {
      module.title = await this.getTranslatedMenuLabel(module.value);
      if (module.hasOwnProperty(KEY_ITEMS)) {
        module.items.forEach(async (item) => {
          item.title = await this.getTranslatedMenuLabel(item.value);
        });
      }
    });

    return menu;
  }

  /**
   * @description Try to log in, if so set session info, cookies, and allowed routes
   * @param loginBody The object with username and password
   * @returns {boolean} If user is logged in or not
   */
  public async login(loginBody: LoginBody): Promise<boolean> {
    try {
      await this.getLoginLabels();
      const res = await this.postAuth(loginBody);

      if (res.infoUsuario.embarcador?.configuracion?.isSsoShipperActive) {
        this.redirectToSsoLogin();

        return false;
      }
      this.currentUser = res.infoUsuario.username;
      const session = {
        token: res.token,
        profile: res.infoUsuario.tipoUsuario.nombre,
        userOid: res.infoUsuario._id,
        username: res.infoUsuario.username,
        shipperOid: res.infoUsuario ? (res.infoUsuario.embarcador ? res.infoUsuario.embarcador._id : undefined) : undefined,
        carrierOid: res.infoUsuario ? (res.infoUsuario.lineaTransporte ? res.infoUsuario.lineaTransporte._id : undefined) : undefined
      };
      this.monitoringUserAuthentication(res.infoUsuario.username, res.infoUsuario._id);
      this.setLanguage(res.infoUsuario);
      await this.toast.getToastrAlertsServiceLabels();
      await this.setInfoSessionAndCookies(res, session, loginBody);
      await this.getLoginLabels();
      await this.setModulesAndViews();
      this.notificationsService.requestNotificationsPermission(res.infoUsuario._id);
    } catch (error) {
      if (error.error.message && error.error.message === AuthErrors.inactiveUser) {
        this.toast.warningAlert(this.loginLabels.inactiveUserError);
      } else if (error.error.message) {
        this.toast.warningAlert(error.error.message);
      } else {
        this.toast.errorAlert(this.loginLabels.loginError);
      }
      return false;
    }
  }

  /**
   * @description Do request to log in
   * @param credentials The object with username and password
   */
  public async postAuth(credentials): Promise<any> {
    return await this.http.post<any>(apiUrl + '/auth', credentials).toPromise();
  }

  /**
   * @description Sets language according to the user information obtained from the login request.
   * @param language The user's language stored in the database.
   * If not language is found, sets the language stored in local storage or the default language (es).
   * @return {void}
   */
  public setLanguage(infoUser: User): void {
    if (infoUser.language) {
      this._languageTranslateService.setLanguage(infoUser.language._key);
    } else {
      this._languageTranslateService.setLanguage();
    }
  }

  /**
   * @description Set session, info and cookies
   * @param info The userInfo to be storaged
   * @param session The session info with all data required
   * @param loginBody The object with username and password
   */
  public async setInfoSessionAndCookies(info: AuthResponse, session: SessionBody, loginBody: LoginBody): Promise<void> {
    this._appService.setInfo(JSON.stringify(session));
    this.local.saveItem(USER_INFO_LOCAL_ID, JSON.stringify(info));
    this.cookieService.set(COOKIENAME_SESSION, JSON.stringify(session), 0, '/');
    this.cookieService.set(COOKIENAME_AUTH, JSON.stringify(loginBody), 0, '/');
  }

  /**
   * @description Sets views availables on menu side bar
   * @param {Array<ViewObject>} newViews Views found for the current user or role
   * @returns {Array<IMenuInterface>} Views with name, module and route|
   */
  private async getMenuByGivenViews(newViews: Array<ViewData>): Promise<Array<IMenuInterface>> {
    const menu = [];
    const groupedViews = _.groupBy(newViews, KEY_MODULENAME);
    let stringifiedRoutes: string;

    for (const view in groupedViews) {
      if (view) {
        const moduleAux = {
          icon: groupedViews[view][0].module.icon,
          title: groupedViews[view][0].module.name,
          value: groupedViews[view][0].module.name
        };
        if (groupedViews[view].length > 1) {
          const itemsAux = [];
          groupedViews[view].forEach((element) => {
            if (!element.section) {
              const viewsInModule = {
                title: element.name,
                routerLink: element.route,
                value: element.name
              };
              itemsAux.push(viewsInModule);
            }
          });
          moduleAux[KEY_ITEMS] = itemsAux;
        } else {
          moduleAux[KEY_ROUTERLINK] = groupedViews[view][0].route;
        }
        menu.push(moduleAux);
      }
    }

    await this.translateMenu(menu);
    await this.sortModulesAndViews(menu);
    stringifiedRoutes = JSON.stringify(this.roadnetViewsValidator(menu));
    this.setAllowedRoutes(stringifiedRoutes);

    return menu;
  }

  /**
   * @description Verify if user has property "role" if so, get role's user and set menu, in other case set menu default
   */
  public async setModulesAndViews(): Promise<IMenuInterface[]> {
    const newViews = [];
    const menu = [];
    let stringifiedRoutes: string;
    const viewData = await this.viewProvider.getViews();
    const userData = this._appService.getUserInfoFromStorage();
    const userMenu = await this._appService.getUserRole().then(async (role) => {
      if (role && userData?.views?.length) {
        userData['views'].forEach(view => {
          const viewAvailable = viewData.find(e => _.isEqual(e._id, view._id));
            if (!_.isUndefined(viewAvailable) && !_.isNull(viewAvailable)) {
              newViews.push(viewAvailable);
            }
        });

        return await this.getMenuByGivenViews(newViews);
      } else if (role) {
        this.roleProvider.getRoleByOId(role._id).then(async res => {
          res[KEY_VIEW].forEach(view => {
            const viewAvailable = viewData.find((e) => _.isEqual(e._id, view._id));
            if (!_.isUndefined(viewAvailable) && !_.isNull(viewAvailable)) {
              newViews.push(viewAvailable);
            }
          });

          return await this.getMenuByGivenViews(newViews);
        });
      } else {
        let menuTranslated;
        const profile = this._appService.getProfile();
        if (_.includes(ALLOWPROFILES, profile)) {
          menuTranslated = await this.translateMenu(MENU_ITEMS_MOCK);
          await this.sortModulesAndViews(menuTranslated);
          stringifiedRoutes = JSON.stringify(this.roadnetViewsValidator(menuTranslated));
          this.setAllowedRoutes(stringifiedRoutes);
          return menuTranslated;
        } else {
          this.router.navigateByUrl(ROUTE_NOPERMISSION);
        }
      }
    });
    return userMenu;
  }

  /**
   * @description Set allow routes in localstorage and redirect to Home after set menu
   * @param stringRoutes Menu items in JSON string
   */
  private setAllowedRoutes(stringRoutes: string): void {
    localStorage.setItem(KEY_ALLOWEDROUTES, stringRoutes);
    this.toast.successAlert(this.loginLabels.loginSuccessfully + ' ' + this.currentUser);
    this.router.navigate([ROUTE_HOME]);
  }

  /**
   * @description Sort modules according to enum Modules and sort views alphabetically
   * @param menu Modules and views to display
   */
  public async sortModulesAndViews(menu: IMenuInterface[]): Promise<void> {
    menu.sort((a, b) => ModuleSequence[a.icon] - ModuleSequence[b.icon]);
    for (const module of menu) {
      if (module.hasOwnProperty(KEY_ITEMS)) {
        await module.items.sort((a, b) => {
          const viewA = a.title.toUpperCase().trim();
          const viewB = b.title.toUpperCase().trim();
          return (viewA < viewB) ? -1 : (viewA > viewB) ? 1 : 0;
        });
      }
    }
  }

  /**
   * @description Returns true if auth cookie exists
   * @returns {boolean} If is authenticated or not
   */
  public isAuthenticated(): boolean {
    const authExists = this.cookieService.check(AUTHCOOKIE);
    return authExists;
  }

  /**
   * @description Redirect to logout component
   * @param {boolean} isExpiredSession Flag for expired session logout
   */
  public async logout(isExpiredSession?: boolean): Promise<void> {
    await this.router.navigateByUrl(EXTERNALLOGOUT);
    if (!isExpiredSession) {
      await this.notificationsService.deleteTokenNotification();
    }
  }

  /**
   * @description Removes roadnet path if are not allowed
   * @param routes The routes associated to the role
   * @returns {Array<IMenuInterface>} All the valid routes to navigate
   */
  public roadnetViewsValidator(routes: Array<IMenuInterface>): Array<IMenuInterface> {
    if (!this.roadnetService.isRoadnetActive()) {
      const validItems = [];

      for (const option of routes) {
        if (option.icon === KEY_SHIPMENT_ICON) {
          if (option.items) {
            for (const item of option.items) {
              if (!item.routerLink.startsWith(ROUTE_ROADNET)) {
                validItems.push(item);
              }
            }
          }
          option.items = validItems;
        }
      }
    }

    return routes;
  }

  /**
   * @description Monitoring user login, authenticating user and login event.
   * @param {string} nickname - User id identifier.
   * @param {string} accountId - User account identifier.
   * @return {void}
   */
  private monitoringUserAuthentication(nickname: string, accountId: string) {
    this.applicationInsightsService.setAuthenticatedUserId(nickname, accountId, true);
  }

  /**
   * @description Creates and displays a toast for redirecting to SSO login.
   */
  private openRedirectToast(): void {
    this.toastr.show(
      `<div class="toast-redirect-content">
        <div class="toast-redirect-div-close-img">
         <img src="assets/icons/icon-close-toast.svg" class="toast-redirect-close-image">
        </div>
        <div class="toast-redirect-info">
         <img src="assets/icons/icon-redirect-toast.svg" class="toast-redirect-image">
         <div>
          <span class="toast-redirect-title">${this.loginLabels.redirectingToSsoTitle}</span><br>
          <span class="toast-redirect-text">${this.loginLabels.redirectingToSsoText}</span>
         </div>
        </div>
       </div>`,
      AppConstants.EMPTY_STRING,
      {
        closeButton: false,
        disableTimeOut: false,
        enableHtml: true,
        positionClass: AUTH_CONSTANTS.POSITION_CLASS,
        progressAnimation: PROGRESS_ANIMATION,
        progressBar: true,
        tapToDismiss: true,
        timeOut: AUTH_CONSTANTS.TIME_OUT_TOAST_REDIRECT,
        toastClass: AUTH_CONSTANTS.TOAST_CLASS
      }
    );
  }

  /**
   * @description Validates if the shipper of the user logged has the sso config active.
   */
  public async isUserLoggedShipperSso(): Promise<boolean> {
    await this.getLoginLabels();
    const auth = this.cookieService.get(COOKIENAME_AUTH).length ? JSON.parse(this.cookieService.get(COOKIENAME_AUTH)): undefined;

    if (auth) {
      const res = await this.postAuth(auth);

      if (res?.infoUsuario?.embarcador?.configuracion?.isSsoShipperActive) {
        this.redirectToSsoLogin();

        return true;
      }
    }

    return false;
  }

  /**
   * @description Redirects the user to the SSO login.
   */
  public redirectToSsoLogin(): void {
    this._appService.loaderStatus(true);
    this.isRedirecting = true;
    this.openRedirectToast();
    this.cookieService.delete(COOKIENAME_SESSION);
    this.cookieService.delete(COOKIENAME_AUTH);

    setTimeout(() => {
      this._appService.loaderStatus(false);
      window.location.href = AUTH_CONSTANTS.ROUTE_SSO_LOGIN_URL;
    }, AUTH_CONSTANTS.TIME_OUT_TOAST_REDIRECT);
  }

  /**
   * @description Validates if the user is being redirecting to SSO login.
   * @returns {boolean} - Returns true if the user is being redirecting to SSO login.
   */
  public isRedirectingToSso(): boolean {
    return this.isRedirecting;
  }
}
